import { RefObject, useEffect, useRef, useState } from 'react';
import { fromEvent, throttleTime } from 'rxjs';

type ViewportIntersectionHookResponse<T> = [RefObject<T>, number] & {
  ref: RefObject<T>;
  intersectionRatio: number;
};

export function useViewportIntersection<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const [ratio, setRatio] = useState(-1);

  const handleScroll = () => {
    const rect = ref.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const negativeSpace = Math.floor((window.innerHeight - rect.height) / 2);

    const topOffset = window.innerHeight - rect.y;
    const centerOffset = rect.y - negativeSpace;
    const bottomOffset = rect.y + rect.height;

    const range = Math.abs(window.innerHeight - negativeSpace);

    if (bottomOffset < 0) {
      setRatio(1);
    } else if (topOffset < 0) {
      setRatio(-1);
    } else {
      setRatio((-1 * centerOffset) / range);
    }
  };

  useEffect(() => {
    handleScroll();

    const scroll$ = fromEvent(window, 'scroll')
      .pipe(throttleTime(10))
      .subscribe(() => handleScroll());

    return () => scroll$.unsubscribe();
  }, []);

  const response = [ref, ratio] as ViewportIntersectionHookResponse<T>;
  response.ref = response[0];
  response.intersectionRatio = response[1];

  return response;
}
